import aranLogo from "./assets/aran_logo_black.webp";
import comboLocks from "./assets/combo_locks.webp";

const App = () => {
  return (
    <div className="max-w-6xl mx-auto p-3 h-screen  flex flex-col items-center justify-center">
      <nav className="fixed top-0 left-0 w-full py-3 px-3">
        <div className="max-w-6xl mx-auto">
          <img src={aranLogo} alt="..." className="w-32 bg" />
        </div>
      </nav>

      <div className=" ">
        <img
          src={comboLocks}
          alt="..."
          className="2xl:w-[32rem] mx-auto w-96"
        />
        <h1
          className="font-bold
              sm:text-[2rem]  text-2xl
              sm:leading-relaxed leading-normal
               font-Inter text-center mt-3
            "
        >
          The World&rsquo;s First <span className="text-red-600">Wi-Fi</span>{" "}
          <br /> Smart Padlock
        </h1>
        <div className="flex flex-wrap gap-4 mt-5 justify-center text-center">
          <a
            href="https://plus.arantec.app"
            className="bg-brandColor text-white w-52 inline-block py-2.5 rounded-lg"
            target="_blank"
            rel="noreferrer"
          >
            Go to Plus Platform
          </a>
          <a
            href="https://www.arantec.app"
            target="_blank"
            rel="noreferrer"
            className="border border-brandColor  text-brandColor w-52  inline-block py-2.5 rounded-lg"
          >
            Go to Website
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
